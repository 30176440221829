/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(darken($secondary, 10), 0.8);
  overflow: hidden;
  position: relative;

  .carousel, .carousel-inner, .carousel-item, .carousel-item::before {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right:0;
    left: 0;
    bottom: 0;
  }

  .carousel-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    top: 70px;
    left: 55px;
    right: 55px;
  }

  .carousel-content {
    background: rgba(darken($secondary, 5), .7);
    padding: 20px;
    color: $white;
    border-top: 5px solid $primary;

    h2 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 700;
    }

  }

  .btn-get-started {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid $primary;
    &:hover {
      background: $primary;
      color: #fff;
      text-decoration: none;
    }
  }

  
  .carousel-inner {
    .carousel-item {
      transition-property: opacity;
      background-position: center bottom;
    }

    .carousel-item,
    .active.carousel-item-start,
    .active.carousel-item-end {
      opacity: 0;
    }

    .active,
    .carousel-item-next.carousel-item-start,
    .carousel-item-prev.carousel-item-end {
      opacity: 1;
      transition: 0.5s;
    }

    .carousel-item-next,
    .carousel-item-prev,
    .active.carousel-item-start,
    .active.carousel-item-end {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    background: none;
    font-size: 30px;
    line-height: 0;
    width: auto;
    height: auto;
    background: rgba($white, .4);
    border-radius: 50px;
    transition: 0.3s;
    color: rgba($white, .6);
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgba($white, .6);
      color: rgba($white, .8);
    }
    
  }

  .carousel-indicators li {
    cursor: pointer;
    background: $white;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    opacity: .6;
    transition: 0.3s;
    &.active {
      opacity: 1;
      background: $primary;
    }
  }
  
  @media (min-width: 1024px) {

    .carousel-content {
      width: 60%;
    }

    .carousel-control-prev, .carousel-control-next {
      width: 5%;
    }
  }

  @media (max-width: 992px) {
    .carousel-container {
      top: 58px;
    }

    .carousel-content {

      margin-bottom: 50px;

      
      h2 {
        margin-bottom: 15px;
        font-size: 22px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  @media (max-height: 500px) {
    height: 120vh;
  }
}
