/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: $white;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);

  .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    a {
      color: $secondary;
      span {
        color: $primary;
      }
    }

    img {
      max-height: 40px;
    }
  }
} 

/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
  margin-left: 20px;
  border-left: 1px solid lighten($secondary, 70);
  a {
    color: lighten($secondary, 56);
    display: inline-block;
    line-height: 0px;
    transition: 0.3s;
    padding-left: 20px;
    i {
      line-height: 0;
    }
    &:hover {
      color: $primary
    }
  }

  @media (max-width: 991px) {
    padding: 0 15px 0 0;
    border-left: 0;
  }

}
