/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: $white;
  font-size: 14px;
  background: $secondary;

  .footer-top {
    padding: 30px 0 0px 0;
    background: lighten($secondary, 5);

    .footer-contact {
      h3 {
        font-size: 22px;
        margin: 0 0 10px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: $font-primary;
        color: rgba($white, .7);
      }
      margin-bottom: 30px;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $white;
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      margin-bottom: 30px;
      ul{
        list-style: none;
        padding: 0;
        margin: 0;

        i {
          padding-right: 2px;
          color: lighten($primary, 5);
          font-size: 18px;
          line-height: 1;
        }

        li {
          padding: 10px 0;
          &:first-child {
            padding-top: 0;
          }
          display: flex;
          align-items: center;
        }

        a {
          color: rgba($white, .6);
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }

  }

  .footer-newsletter {
    font-size: 15px;
    color: rgba($white, .7);

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $white;
      position: relative;
      padding-bottom: 12px;
    }

    form {
      margin-top: 30px;
      background: #fff;
      padding: 6px 10px;
      position: relative;
      border-radius: 4px 0 0 4px;
      text-align: left;

      input[type="email"] {
        border: 0;
        padding: 4px 8px;
        width: calc(100% - 100px);
      }

      input[type="submit"] {
        position: absolute;
        top: 0;
        right: -4px;
        bottom: 0;
        border: 0;
        background: none;
        font-size: 16px;
        padding: 0 20px;
        background: $primary;
        color: $white;
        transition: 0.3s;
        border-radius: 0 4px 4px 0;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        &:hover {
          background: lighten($primary, 10);
        }
      }
    }
  }

  .credits {
    padding-top: 5px;
    font-size: 13px;
    color: $white;
  }

  .social-links {
    a {
      font-size: 18px;
      display: inline-block;
      background: rgba($white, .08);
      color: $white;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 4px;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;
      &:hover {
        background: $primary;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
